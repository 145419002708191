import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  or: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    '&:before, &:after': {
      content: '""',
      flex: '1 1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      margin: 'auto',
    },
  },
}))

const OrLine = () => {
  const classes = useStyles()
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className={classes.or}
    >
      or
    </Typography>
  )
}

export default OrLine
