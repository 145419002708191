import React from 'react'

import { makeStyles } from '@material-ui/core'
import LoginWithGoogleButton from './LoginWithGoogleButton'
import LoginWithCleverButton from './LoginWithCleverButton'
import LoginWithClassLinkButton from './LoginWithClassLinkButton'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

type SSOButtonsProps = {
  actualSource?: string
  scid?: number
}

const SSOButtons = ({ actualSource, scid }: SSOButtonsProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LoginWithGoogleButton
        source={actualSource}
        scid={scid}
      />
      <LoginWithCleverButton
        source={actualSource}
        scid={scid}
      />
      <LoginWithClassLinkButton
        source={actualSource}
        scid={scid}
      />
    </div>
  )
}

export default SSOButtons
