import React, { useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'

import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import { Link } from 'react-router-dom'
import cookie from 'cookie'
import Title from 'components/Title'
import PasswordForm, { PasswordFormValues } from 'components/RegistrationForm/passwordForm'
import ValidationError from './validationError'
import { useRegisterParentUser, useRegisterStudentUser, validateParentInvite, validateStudentInvite } from './helper'
import { Typography, makeStyles } from '@material-ui/core'
import ContactCounselorForm from './contactCounselorForm'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
    display: 'inline-block',
    cursor: 'pointer',
  },
}))

type TokenAuth = {
  Email: string
  ConfirmationCode: string
  Token: string
  MatchedHighSchoolStudent?: {
    FirstName: string
    LastName: string
    HighSchoolStudentId: string
  }
  HighSchool?: {
    HighSchoolId: string
  }
  FirstName: string
  IsMiddleSchoolStudent?: boolean
}

type UserType = {
  name?: string
  location?: string
  email?: string
  highschool?: string
}

type RegisterFromInviteProps = {
  token?: string
  userType: 'parent' | 'student'
  id?: string
  code?: string
}
const RegisterFromInviteRoute = ({ token, id, code, userType }: RegisterFromInviteProps) => {
  const classes = useStyles()
  const [tokenAuth, setTokenAuth] = useState<TokenAuth | undefined>(undefined)
  const [user, setUser] = useState<UserType>({ name: '', location: '' })
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState<PasswordFormValues | undefined>(undefined)
  const [confirmError, setConfirmError] = useState(false)
  const [showContactCounselor, setShowContactCounselor] = useState(false)

  useEffect(() => {
    // Validate Parent with Id and Code from URL
    if (userType === 'parent') {
      id && code && validateParentInvite(id, code, setError, setInitialValues, setLoading, setTokenAuth, setUser)
    }
    // Validate Student with token from URL
    if (userType === 'student') {
      token && validateStudentInvite(token, setError, setInitialValues, setLoading, setTokenAuth, setUser)
    }
    setLoading(false)
  }, [code, id, token, userType])

  const registerParentUser = useRegisterParentUser()
  const registerStudentUser = useRegisterStudentUser()

  const clearCookies = () => {
    const path = `/student/confirm/link/${tokenAuth?.HighSchool?.HighSchoolId}/${token}`
    document.cookie = cookie.serialize('remembered', path, {
      path: '/',
    })
  }

  const registerUser = (values: PasswordFormValues, props: FormikHelpers<PasswordFormValues>) => {
    props.setSubmitting(true)
    // Register Parent
    if (userType === 'parent') {
      registerParentUser(values, tokenAuth, setConfirmError, props.setSubmitting)
    }
    // Register Student
    if (userType === 'student') {
      registerStudentUser(values, tokenAuth, setConfirmError, props.setSubmitting)
    }
    props.setSubmitting(false)
  }

  const getSubtitle = () => {
    if (userType === 'student' && user.highschool && user.location) {
      return `Please activate your account with ${user.highschool} in ${user.location}`
    }
    if (userType === 'parent' && user.email) {
      return `Please activate your account for ${user.email}.`
    }
    return 'Please activate your account.'
  }
  const handleNotYou = () => {
    setShowContactCounselor(!showContactCounselor)
  }

  if (error) {
    return <ValidationError />
  }
  if (showContactCounselor) {
    return (
      <ContactCounselorForm
        onCancel={handleNotYou}
        student={tokenAuth?.MatchedHighSchoolStudent}
        highschool={tokenAuth?.HighSchool}
        email={tokenAuth?.Email}
      />
    )
  }
  return (
    <ComponentLoader loaded={!loading}>
      <Title
        title={`Welcome${user.name ? `, ${user.name}` : ''} ✨`}
        subtitle={getSubtitle()}
      />
      {!tokenAuth?.IsMiddleSchoolStudent && (
        <Link
          to={`/student/confirm/link/${tokenAuth?.HighSchool?.HighSchoolId}/${token}`}
          onClick={clearCookies}
          className={classes.link}
        >
          Sign in with an existing Scoir account
        </Link>
      )}
      <PasswordForm
        initialValue={initialValues}
        handleSubmit={registerUser}
        confirmError={confirmError}
        useCaptcha={userType === 'student'}
      />
      {userType === 'student' && (
        <Typography color="textSecondary">
          Not you?{' '}
          <span
            className={classes.link}
            onClick={handleNotYou}
          >
            Let us know
          </span>
        </Typography>
      )}
    </ComponentLoader>
  )
}

export default RegisterFromInviteRoute
